<template>
  <div class="m-pg-sub">
    <page-cover code="11" layer="rgba(0,0,0,.3)">
      <h2 class="m-page-title">我的数据</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/personal' }">{{$t('page.personal.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>我的数据</el-breadcrumb-item>
      </el-breadcrumb>
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <div class="action">
        <el-button type="primary" @click="onDownloadImportTpl">{{$t('action.downloadImportTpl')}}</el-button>
        <el-upload
          class="btn-import"
          name="fileInput"
          :headers="{
            Authorization: token ? 'Bearer ' + token : ''
          }"
          :show-file-list="false"
          :action="importData.url"
          accept=".xlsx,.xls"
          :on-progress="onImportDataProgress"
          :on-error="onImportDataError"
          :on-success="onImportDataSuccess">
          <el-button :loading="importData.loading" type="primary">{{$t('action.importData')}}</el-button>
        </el-upload>
        <el-button :loading="exportData.loading" type="primary" @click="onExport">{{$t('action.exportData')}}</el-button>
      </div>
      <el-table
        class="tb-data el-table--custom"
        v-loading="table.loading"
        :data="table.data"
        size="medium"
        stripe
        tooltip-effect="light">

        <!-- 样品号 -->
        <el-table-column
          prop="Sample_Name"
          :label="$t('model.sample.Sample_Name')"
          min-width="180"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 大洲或大洋 -->
        <el-table-column
          prop="Continent_or_Ocean"
          :label="$t('model.sample.Continent_or_Ocean')"
          min-width="180"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 国家 -->
        <el-table-column
          prop="Country"
          min-width="180"
          :label="$t('model.sample.Country')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地体 -->
        <el-table-column
          prop="Terrane"
          min-width="180"
          :label="$t('model.sample.Terrane')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地球动力学背景 -->
        <el-table-column
          prop="Geodynamic_settings"
          min-width="180"
          :label="$t('model.sample.Geodynamic_settings')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩石或底层单元 -->
        <el-table-column
          prop="Rock_or_stratigraphic_unit"
          min-width="190"
          :label="$t('model.sample.Rock_or_stratigraphic_unit')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地质时代 -->
        <el-table-column
          prop="Geological_period"
          min-width="180"
          :label="$t('model.sample.Geological_period')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩性 -->
        <el-table-column
          prop="Lithology"
          min-width="180"
          :label="$t('model.sample.Lithology')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 变质级别 -->
        <el-table-column
          prop="Metamorphic_grade"
          min-width="180"
          :label="$t('model.sample.Metamorphic_grade')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 测定对象或矿物 -->
        <el-table-column
          prop="Object_or_mineral_determined"
          min-width="214"
          :label="$t('model.sample.Object_or_mineral_determined')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 刊物名 -->
        <el-table-column
          prop="Journal"
          min-width="180"
          :label="$t('model.sample.Journal')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 题名 -->
        <el-table-column
          prop="Title"
          min-width="180"
          :label="$t('model.sample.Title')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Year"
          min-width="180"
          :label="$t('model.sample.Year')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 第一作者姓名 -->
        <el-table-column
          prop="First_Author"
          min-width="180"
          :label="$t('model.sample.First_Author')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 表达方式 -->
        <el-table-column
          prop="Expression"
          min-width="180"
          :label="$t('model.sample.Expression')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄值(Ma) -->
        <el-table-column
          prop="Age"
          min-width="180"
          :label="$t('model.sample.Age')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 误差2SE(Ma) -->
        <el-table-column
          prop="SE2"
          min-width="180"
          :label="$t('model.sample.SE2')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄解释 -->
        <el-table-column
          prop="Interpretation"
          min-width="180"
          :label="$t('model.sample.Interpretation')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="160"
          :label="$t('word.operation')"
          show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button :title="$t('action.delete')" size="mini" type="primary" icon="el-icon-delete" @click="onDel(row)"></el-button>
            <el-button :title="$t('action.detail')" size="mini" type="primary" icon="el-icon-document" @click="onDetail(row)"></el-button>
            <el-button :title="$t('page.result.action.mark')" size="mini" type="primary" icon="el-icon-location" @click="onMark(row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-size.sync="page.size"
        :current-page.sync="page.index"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import setting from '@/setting'
import commonApi from '@/api/common'
import dSampleApi from '@/api/dSample'
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      dict: {
        list_lab_Status: [], // 预约状态
      },
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 10,
        total: 0
      },
      exportData: {
        loading: false,
        downloadUrl: `${setting.baseUrl.api}D_Sample/DownLoadFile`
      },
      importData: {
        url: `${setting.baseUrl.api}D_Sample/Import`,
        loading: false
      },
    }
  },
  computed: {
    ...mapState(['user', 'token'])
  },
  inject: ['validateLogin'],
  methods: {
    async getDict() {
      const dicts = await commonApi.getVueDictionary([
        "list_lab_Status"
      ])
      if (dicts && dicts.length) {
        this.dict.list_lab_Status = dicts.find(item => item.dicNo === 'list_lab_Status').data
      }
    },
    getData() {
      this.table.loading = true
      let wheres = []
      wheres.push({name: 'CreateID', value: this.user.user_Id, displayType: 'text'})
      dSampleApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: this.page.index,
        rows: this.page.size
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.table.loading = false
      })
    },
    // 删除
    onDel(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dSampleApi.Del([row.Sample_ID]).then(res => {
          if(res.status === true) {
            this.getData()
          } else {
            this.$message.error('删除失败')
          }
        })
      })
    },
    onDetail(item) {
      this.$router.push(`/search/result-detail?sampleId=${item.Sample_ID}`)
    },
    onMark(item) {
      window.open(`${setting.visualized.webUrl}?mode=label&sampleID=${item.Sample_ID}`, '_blank')
    },
    onPageChange(val) {
      this.getData()
    },
    onDownloadImportTpl(val) {
      let url = location.origin + '/static/样品信息(导入模板).xlsx'
      window.open(url, '_blank')
    },
    async onExport() {
      this.exportData.loading = true
      let wheres = []
      wheres.push({name: 'CreateID', value: this.user.user_Id, displayType: 'text'})
      let { data } = await dSampleApi.exportData({
        wheres: JSON.stringify(wheres)
      })
      this.exportData.loading = false
      window.open(`${this.exportData.downloadUrl}?path=${data}`, '_blank')
    },
    onImportDataProgress() {
      this.importData.loading = true
    },
    onImportDataError(res) {
      console.log(res)
      this.importData.loading = false
      this.$message.error({
        message: res.message
      })
    },
    onImportDataSuccess(res, file) {
      this.importData.loading = false
      if (res.status) {
        this.$message.success({
          message: '导入成功！'
        })
      } else {
        this.$message.error({
          message: res.message
        })
      }
    }
  },
  created() {
    this.validateLogin()
  },
  mounted() {
    this.getDict()
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .filter {
      padding: 20px 0;
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      /deep/ .el-input {
        width: 193px;
      }
    }
    .action {
      padding: 12px 16px;
      text-align: right;
      .btn-import {
        display: inline-block;
        margin:0 10px;
      }
    }
    .step {
      margin-top: 30px;
    }
  }
</style>
